import request from '@/utils/request'

const api = {
  seal: '/pc/seal',
  fence: '/pc/seal/rail',
  level: '/pc/department/departmentStaffDetails',
  sreach: '/pc/department/departmentStaffSearch'
}

// 获取印章列表
export function getSealList(params) {
  return request({
    url: `${api.seal}`,
    method: 'GET',
    params
  })
}

// 添加印章
export function addSeal(params) {
  return request({
    url: `${api.seal}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 修改印章
export function editSeal(params) {
  return request({
    url: `${api.seal}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 删除印章
export function deleteSeal(params) {
  return request({
    url: `${api.seal}/${params.id}`,
    method: 'DELETE'
  })
}

// 查看印章
export function sealInfor(params) {
  return request({
    url: `${api.seal}/${params.id}`,
    method: 'GET'
  })
}

// 获取印章全部人员
export function getSealStaffList() {
  return request({
    url: `${api.seal}/allStaff`,
    method: 'GET'
  })
}

// 获取电子围栏
export function getFenceList(params) {
  return request({
    url: `${api.fence}`,
    method: 'GET',
    params
  })
}

// 添加电子围栏
export function addFence(params) {
  return request({
    url: `${api.fence}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 更新电子围栏
export function editFence(params) {
  return request({
    url: `${api.fence}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 删除电子围栏
export function deleteFence(params) {
  return request({
    url: `${api.fence}/${params.id}`,
    method: 'DELETE'
  })
}

//获取部门，人员
export function getLevelList(params) {
  return request({
    url: `${api.level}`,
    method: 'GET',
    params
  })
}

//模糊搜索部门，人员
export function sreachLevelList(params) {
  return request({
    url: `${api.sreach}`,
    method: 'GET',
    params
  })
}

// 添加电子印章
export function addElectronicSeal(params) {
  return request({
    url: '/pc/qys/electronic/seal',
    method: 'POST',
    data: params
  })
}

// 修改电子印章
export function setElectronicSeal(params) {
  return request({
    url: '/pc/qys/electronic/seal',
    method: 'PUT',
    data: params
  })
}

// 检验当前电子印章是否在使用
export function testSeal(id) {
  return request({
    url: `/pc/qys/electronic/seal/verity/${id}`,
    method: 'GET'
  })
}

//判断用户是否有指定的权限
export function permissionCheck(params) {
  return request({
    url: `/pc/permission`,
    method: 'GET',
    params
  })
}

//获批量设置详情页
export function getSettingDetail(params) {
  return request({
    url: `/pc/seal/batch`,
    method: 'GET',
    params
  })
}

//批量设置印章
export function batchSetting(params) {
  return request({
    url: '/pc/seal/batch',
    method: 'PUT',
    data: params
  })
}
